import type {useI18n} from 'vue-i18n'
import type {useToastStore} from '../stores/toast'

type ToastStore = ReturnType<typeof useToastStore>
type T = ReturnType<typeof useI18n>['t']

export function addDefaultErrorToast(toastStore: ToastStore, t: T, error: unknown) {
  console.error(error)
  toastStore.addErrorToast(t('alert.title.error'), t('alert.message.error'))
}

export function addDefaultSuccessToast(toastStore: ToastStore, t: T) {
  toastStore.addSuccessToast(t('alert.title.success'), t('alert.message.success'))
}

export const defaultErrorToastHandler = (toastStore: ToastStore, t: T) => (error: unknown) =>
  addDefaultErrorToast(toastStore, t, error)
export const defaultSuccessToastHandler = (toastStore: ToastStore, t: T) => () => addDefaultSuccessToast(toastStore, t)
